<template>
  <div>
    <van-nav-bar title="调试菜单" />
    <van-cell-group>
      <van-cell v-for="item in list" @click="handleTo(item)" :key="item.value" :title="item.label" isLink></van-cell>
    </van-cell-group>
  </div>
</template>

<script>
// @ is an alias to /src

import { mapActions, mapState } from 'vuex';
import router from '../router';

export default {
  name: 'About',
  data() {
    return {
      list:[]
    }
  },
  created() {
    // console.log(router);
    this.list = router.options.routes.filter(f=>f.meta).map(m=>({label:m.meta.title, value:m.path}))
  },
  computed: {
    ...mapState([]),
  },
  methods: {
    ...mapActions([]),
    handleTo(item) {
      this.$router.push({
        path:item.value,
        query:this.$route.query
      });
    }
  },

}
</script>
<style lang="less" scoped>

</style>
